
		import { deserialise } from '@swup/astro/serialise';
		import { onIdleAfterLoad } from '@swup/astro/idle';
		

		async function initSwup() {
			
		const [Swup, SwupPreloadPlugin, SwupScrollPlugin, SwupBodyClassPlugin, SwupScriptsPlugin, SwupOverlayTheme] = await Promise.all([import('@swup/astro/client/Swup').then((m) => m.default), import('@swup/astro/client/SwupPreloadPlugin').then((m) => m.default), import('@swup/astro/client/SwupScrollPlugin').then((m) => m.default), import('@swup/astro/client/SwupBodyClassPlugin').then((m) => m.default), import('@swup/astro/client/SwupScriptsPlugin').then((m) => m.default), import('@swup/astro/client/SwupOverlayTheme').then((m) => m.default)]);
	

			const swup = new Swup({
				animationSelector: "[class*=\"transition-\"]",
				containers: ["main"],
				cache: true,
				plugins: [
					new SwupPreloadPlugin(deserialise("{\"preloadHoveredLinks\":true,\"preloadVisibleLinks\":false}")), new SwupScrollPlugin(deserialise("{}")), new SwupBodyClassPlugin(deserialise("{}")), new SwupScriptsPlugin(deserialise("{}")), new SwupOverlayTheme(deserialise("{\"mainElement\":\"main\",\"color\":\"#000\",\"direction\":\"to-right\",\"duration\":\"0.2s\"}"))
				]
			});

			window.swup = swup;
		}

		onIdleAfterLoad(initSwup);
	
import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://3980dc24dd4a6cbe00ad71338a2f834c@o56440.ingest.us.sentry.io/4508126150656000",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});